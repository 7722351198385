<script>
import BVForm from '@/components/BVForm/BVForm.vue';

export default {
    name: 'fichaIndicadorePESV',
    components: {
        BVForm
    },
    async mounted() {
        this.setSelectorSucursal()
    },
    data() {
        return {
            model: [{
                name: "sucursalId",
                value: "",
                label: "Seleccionar sucursal",
                type: "select",
                validations: "required",
                options: []
            }]
        }
    },
    methods: {
        async setSelectorSucursal() {
            const empresaId = this.$store.getters.userLoggedIn.empresa.id;
            const data = await this.$store.dispatch("get", { path: `Sucursal/ListSucursalEmpresa/${empresaId}` });
            this.model[0].options = data
        },
        reader( sucursal ) {
            const descripcion = this.model[0].options.find( suc => suc.id == sucursal.value ).descripcion
            this.$emit('getSucursal', {...sucursal, descripcion})
        }
    },

}
</script>

<template>
    <BVForm :schema="model" :observer="['sucursalId']" @watcher="reader" hide-footer />
</template>